







































































































import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import ButtonComponent from '@/components/ButtonComponent.vue';
import InputText from '@/components/InputText.vue';
import { Component } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import AlertComponent from '@/components/AlertComponent.vue';
import { validationMixin } from 'vuelidate';
import { namespace } from 'vuex-class';
import AlertType from '@/utils/enums/AlertType';
import CommunityUser from '@/models/graphql/CommunityUser';
import SignInCanProceed from '@/utils/types/SignInCanProceed';
import AuthPageGuard from '@/guards/AuthPageGuard';

const authenticationStore = namespace('AuthenticationStore');

@Component({
  components: {
    ButtonComponent,
    InputText,
    AlertComponent,
  },
  data(): object {
    return {
      firstSubmit: false,
      submitError: false,
      emailError: false,
      passwordError: false,
      serviceError: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
  },
  beforeRouteEnter: AuthPageGuard.beforeEnter,
})
export default class SignInEnterPassword extends VueBaseWidget {
  @authenticationStore.State
  private readonly authEmail!: string | null;

  @authenticationStore.State
  private readonly signInCanProceed!: SignInCanProceed | null;

  @authenticationStore.Action
  private login!: (credentials: {
    username: string;
    password: string;
  }) => Promise<CommunityUser | null | undefined>;

  @authenticationStore.Action
  private magicLink!: (payload: {
    username: string;
  }) => Promise<{ success: boolean }>;

  private alertType = AlertType;

  private disableSubmit = false;

  private form = {
    email: '',
    password: '',
  };

  created(): void {
    if (this.authEmail) {
      this.form.email = this.authEmail;
    } else {
      this.$router.push({ name: 'sign-in' });
    }
  }

  private onChange(fieldType: string): void {
    this.$data.submitError = false;
    if (this.$v) {
      switch (fieldType) {
        case 'email':
          this.$data.emailError = this.$v.form.email ? this.$v.form.email.$invalid : false;
          break;
        case 'password':
          this.$data.passwordError = this.$v.form.password ? this.$v.form.password.$invalid : false;
          break;
        default:
          break;
      }
    }
  }

  private onGoBack(): void {
    this.$router.back();
  }

  private onSubmit(): void {
    this.disableSubmit = true;
    this.$data.firstSubmit = true;
    this.onChange('email');
    this.onChange('password');
    if (this.form.email && !this.$data.emailError && this.form.password && !this.$data.passwordError) {
      this.login({
        username: this.form.email,
        password: this.form.password,
      })
        .then((response) => {
          if (response === null) {
            this.$data.submitError = true;
          }
          this.disableSubmit = false;
        })
        .catch(() => {
          this.$data.serviceError = true;
          this.disableSubmit = false;
        });
    } else {
      this.$data.submitError = true;
      this.disableSubmit = false;
    }
  }

  private onMagicLink(): void {
    this.magicLink({
      username: this.form.email,
    })
      .then((response: { success: boolean }) => {
        if (response.success) {
          this.$router.push({ name: 'email-sent' });
        }
      });
  }

  private onResetPassword(): void {
    this.$router.push({ name: 'password-reset' });
  }
}
